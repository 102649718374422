.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 266px;
    height: 475px;
    padding: 16px;
    border-width: 4px;
    border-style: solid;

    .card-image-container {
        width: 100%;
        height: 100%;

        .card-image {
            width: 225px;
            margin-bottom: 24px;
        }
    }

    .card-title {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 0 8px;
        margin-top: 20px;

        .title {
            display: flex;
            font-size: 20px;
            flex-direction: column;
            justify-content: center;
        }

        .card-icon {
            margin-top: 4px;

            .button-icon {
                font-size: 32px;
            }
        }
    }

    &.image-only {
        width: auto;
        max-width: none;
        height: auto;
        padding: 0;

        .card-image {
            width: auto;
            height: 400px;
            margin-bottom: 0;
        }
    }

    &.clickable {
        cursor: pointer;
    }
}