$color-1: #100F22;
$color-2: white;

$font-1: 'Noto Serif JP', serif;
$font-2: 'Nunito Sans', sans-serif;

#root {
    .app {
        &.light-theme {
            // General app
            font-family: $font-2;
            font-weight: 300;
            color: $color-1;
            background-color: $color-2;

            // Components
            .button {
                &.action {
                    color: $color-2;
                    background-color: $color-1;
                    border-color: $color-1;
                }

                &.outlined {
                    color: $color-1;
                    background-color: transparent;
                    border-color: $color-1;
                }

                &.text {
                    font-family: $font-1;
                    color: $color-1;
                    border-color: transparent;
                }

                &.nav-link {
                    font-family: $font-1;
                    color: $color-1;
                    border-color: transparent;

                    .active-indicator {
                        background-color: $color-1;
                    }
                }

                &.icon {
                    border-color: transparent;

                    &:hover {
                        color: $color-2;
                        background-color: $color-1;
                    }
                }
            }

            .loader {
                path.loader-fill {
                    fill: $color-1;
                }
            }

            .logo {
                font-family: $font-1;
                font-weight: 600;
            }

            .card {
                border-color: $color-1;

                .card-title {
                    .title {
                        font-family: $font-1;
                        color: $color-1;
                    }
                }
            }

            .header {
                font-family: $font-1;
                font-weight: 600;
                color: $color-1;
            }

            .theme-toggle {
                border: solid 2px $color-1;
            }

            .dropdown-options {
                &.expanded {
                    background-color: $color-2;
                    // box-shadow: 1px 8px 7px 5px #00000040;
                    border: solid 2px $color-1;
                }
            }

            // Pages
            .about {
                .about-me-card {
                    border: solid 4px $color-1;

                    .about-image {
                        border: solid 4px $color-1;
                    }
                }
            }

            .albums {
                &::after {
                    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 1) 90%, rgba(255, 255, 255, 1) 100%);
                }
            }
        }
    }
}