.logo {
    height: 100%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 3fr;
    align-items: center;
    text-transform: uppercase;
    cursor: pointer;

    .letters {
        font-size: 48px;
        margin-right: 8px;
    }

    .title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 2px;

        .line {
            &.first {
                letter-spacing: 0px;
            }

            &.second {
                letter-spacing: 3.5px;
            }
        }
    }

}