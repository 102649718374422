.about {
    display: flex;
    height: 100%;
    align-items: flex-start;

    .about-main {
        display: flex;
        width: 100%;
        position: relative;
        justify-content: center;
        align-items: center;
        margin-top: 40px;

        .about-me-card {
            width: 90%;
            max-width: 1400px;
            display: flex;
            align-items: center;
            padding: 60px;

            .about-image {
                max-width: 300px;
                margin-right: 24px;
                border-radius: 1000px;
                object-fit: cover;
            }

            .about-text-container {
                width: 100%;
                display: flex;
                flex-direction: column;

                .about-text {
                    font-size: clamp(16px, 2vw, 24px);
                    margin-left: 24px;
                }

                .contact-text {
                    max-width: 300px;
                    display: flex;
                    justify-content: space-between;
                    margin-top: 24px;
                    margin-left: 24px;
                }
            }
        }

        .loader-box {
            width: 40%;
            align-self: center;
            margin: 0 auto;
        }
    }
}

@media only screen and (max-width: 875px) {
    .about {
        overflow-y: auto;

        .about-main {
            margin-top: 20px;

            .about-me-card {
                width: 100%;
                flex-direction: column;
                overflow-y: auto;
                padding: 40px 24px 20px;

                .about-image {
                    max-width: 80%;
                    max-height: 150px;
                    margin: 0 16px 20px;
                }

                .about-text-container {
                    .about-text {
                        text-align: center;
                        margin: 0 0 16px;
                    }

                    .contact-text {
                        width: 280px;
                        align-self: center;
                        margin: 0;
                    }
                }
            }
        }
    }
}