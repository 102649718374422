body {
    margin: 0;
}

#root {
    * {
        box-sizing: border-box;
    }
}

.app {
    display: flex;
    position: relative;
    width: 100vw;
    height: 100vh;
    // min-width: 900px;
    flex-direction: column;
    padding: 0 20px 20px;
    overflow: hidden;
}

* {
    transition: background-color 0.2s, color 0.2s;
    transition-timing-function: ease;
}