.button {
    display: flex;
    width: fit-content;
    min-width: 120px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    padding: 8px 16px;
    border-style: solid;
    border-width: 4px;
    transition: 0.2s ease;
    cursor: pointer;

    .label {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    &-icon {
        margin-right: 4px;
        transition: 0.1s ease;
    }

    &.text {
        text-transform: uppercase;
        border: none;
    }

    &.nav-link {
        width: 115px;
        min-width: auto;
        text-transform: uppercase;
        text-decoration: none;
        border: none;

        .active-indicator {
            width: calc(100% + 24px);
            height: 4px;
            margin-top: 2px;
            transition: 0.4s ease;
            transform: scaleX(0);
            filter: opacity(0);
        }

        &.active {
            .active-indicator {
                transform: scaleX(1);
                filter: opacity(1);
            }
        }
    }

    &.icon {
        width: 40px;
        height: 40px;
        min-width: auto;
        padding: 0;
        border: none;

        .button-icon {
            margin-right: 0px;
        }

        &:hover {
            transform: translateY(0);
        }

        &:active {
            transform: translateY(4px);
        }
    }

    &:hover {
        transform: translateY(-4px);
    }

    &:active {
        transform: translateY(2px);
    }
}