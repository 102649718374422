.material-icons {
    &.clickable {
        cursor: pointer;
        transition: 0.2s ease;

        &:hover {
            transform: scale(1.1);
        }

        &:active {
            transform: scale(0.9);
        }
    }
}